import { apiReset } from 'redux/api';
import {
  setCurrentStep,
  resetSteps,
  copyStepsStateInMemory,
  copyAnswerStateInMemory,
} from './actions';

const onNextStep = (step, data) => {
  return async (dispatch, getState) => {
    dispatch(setCurrentStep(step, data));
    dispatch(copyStepsStateInMemory(getState().steps));
    if (step === 'step5') {
      dispatch(copyAnswerStateInMemory(getState().api.SUBMIT_ANSWERS.data));
      dispatch(
        apiReset({
          label: 'SUBMIT_ANSWERS',
        })
      );
      dispatch(resetSteps());
    }
  };
};

export { onNextStep };
