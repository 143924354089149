import {
  SET_CURRENT_STEP,
  RESET_STEPS,
  RESET_STEPS_IN_MEMORY,
  COPY_STEPS_IN_MEMORY,
  COPY_ANSWER_IN_MEMORY,
} from './types';

const INITIAL_STATE = {
  step1: null,
  step2: null,
  step3: null,
  step4: null,
  step5: null,
};
const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_STEP:
      return {
        ...state,
        [action.payload.step]: action.payload.data,
      };
    case RESET_STEPS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

const IN_MEMORY_INITIAL_STATE = {
  step1: null,
  step2: null,
  step3: null,
  step4: null,
  step5: null,
};

export const inMemoryReducer = (state = IN_MEMORY_INITIAL_STATE, action) => {
  switch (action.type) {
    case COPY_STEPS_IN_MEMORY:
      return action.payload.stepsState;
    case RESET_STEPS_IN_MEMORY:
      return IN_MEMORY_INITIAL_STATE;
    default:
      return state;
  }
};

export const inMemoryAnswerReducer = (state = {}, action) => {
  switch (action.type) {
    case COPY_ANSWER_IN_MEMORY:
      return action.payload.answerState;
    default:
      return state;
  }
};

export default appReducer;
