import {
  SET_CURRENT_STEP,
  RESET_STEPS,
  RESET_STEPS_IN_MEMORY,
  COPY_STEPS_IN_MEMORY,
  COPY_ANSWER_IN_MEMORY,
} from './types';

const setCurrentStep = (step, data) => ({
  type: SET_CURRENT_STEP,
  payload: { step, data },
});

const resetSteps = () => ({
  type: RESET_STEPS,
});

const resetStepsInMemory = () => ({
  type: RESET_STEPS_IN_MEMORY,
});

const copyStepsStateInMemory = stepsState => ({
  type: COPY_STEPS_IN_MEMORY,
  payload: { stepsState },
});

const copyAnswerStateInMemory = answerState => ({
  type: COPY_ANSWER_IN_MEMORY,
  payload: { answerState },
});

export {
  setCurrentStep,
  resetSteps,
  resetStepsInMemory,
  copyStepsStateInMemory,
  copyAnswerStateInMemory,
};
