import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from '../reducers';
import apiMiddleware from '../middleware/api';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['steps'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  compose(applyMiddleware(apiMiddleware, thunk))
);

const persistor = persistStore(store);

export default { store, persistor };
