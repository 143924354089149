import axios from 'axios';
import { toast } from 'react-toastify';
import get from 'lodash.get';

import { API } from '../api/types';
import { apiError, apiStart, apiSuccess } from '../api/actions';

const wrongAdressError =
  "Impossible de géolocaliser l'adresse. Merci de vérifier votre saisie.";

const apiMiddleware = ({ dispatch }) => next => action => {
  next(action);

  if (action.type !== API) return;

  const {
    url,
    method,
    data,
    onSuccess,
    onFailure,
    label,
    headers,
  } = action.payload;
  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

  // axios default configs
  axios.defaults.baseURL = process.env.REACT_APP_API_URL || '';
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  if (label) {
    dispatch(apiStart(label));
  }

  axios
    .request({
      url,
      method,
      headers,
      [dataOrParams]: data,
    })
    .then(({ data }) => {
      onSuccess(data);
      dispatch(apiSuccess(label, data));
    })
    .catch(error => {
      const status = get(error, 'response.status');
      const descriptionError = get(error, 'response.data.hydra:description');
      if (status === 400 && descriptionError === wrongAdressError) {
        toast.warn(wrongAdressError, {
          autoClose: false,
        });
      }
 else {
        toast.error(`(${label}) ${error}`, {
          autoClose: false,
        });
      }

      dispatch(apiError(label, error));
      onFailure(error);
    });
};

export default apiMiddleware;
