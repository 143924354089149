import { API, API_RESET, API_RESET_ALL } from './types';
import reducer from './reducer';

export function apiAction({
  url = '',
  method = 'GET',
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = '',
  headersOverride = null,
}) {
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headersOverride,
    },
  };
}

export function apiReset({ label }) {
  return {
    type: API_RESET,
    payload: { label },
  };
}

export const apiResetAll = () => ({
  type: API_RESET_ALL,
});

export default reducer;
