import { combineReducers } from 'redux';
import apiReducer from 'redux/api';
import stepsReducer, {
  inMemoryReducer,
  inMemoryAnswerReducer,
} from 'scenes/home/steps/redux';

const rootReducer = combineReducers({
  api: apiReducer,
  steps: stepsReducer,
  stepsInMemory: inMemoryReducer,
  answerInMemory: inMemoryAnswerReducer,
});

export default rootReducer;
