const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
const RESET_STEPS = 'RESET_STEPS';
const RESET_STEPS_IN_MEMORY = 'RESET_STEPS_IN_MEMORY';
const COPY_STEPS_IN_MEMORY = 'COPY_STEPS_IN_MEMORY';
const COPY_ANSWER_IN_MEMORY = 'COPY_ANSWER_IN_MEMORY';

export {
  SET_CURRENT_STEP,
  RESET_STEPS,
  RESET_STEPS_IN_MEMORY,
  COPY_STEPS_IN_MEMORY,
  COPY_ANSWER_IN_MEMORY,
};
